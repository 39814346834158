$DVAG-fontweight-regular: 400;
$DVAG-fontweight-medium: 500;
$DVAG-fontweight-bold: 700;

$DVAG-breakpoint-SM: 576px;
$DVAG-breakpoint-MD: 768px;
$DVAG-breakpoint-LG: 992px;
$DVAG-breakpoint-XL: 1200px;

// Primaerfarbe
$DVAG-color-gold: #a7893d;
$DVAG-color-gold-dunkel: #856d30;
$DVAG-color-gold-mittel: #957b36;
$DVAG-color-gold-hell: #e3dac4;

// Sekundaerfarbe
$DVAG-color-weiss: #ffffff;

// Grautoene
$DVAG-color-anthrazit: #424242;
$DVAG-color-dunkelgrau-30: #4c4c4c;
$DVAG-color-dunkelgrau-40: #666666;
$DVAG-color-dunkelgrau-50: #7f7f7f;
$DVAG-color-dunkelgrau-70: #c1c1c1;
$DVAG-color-hellgrau-85: #dedede;
$DVAG-color-hellgrau-90: #ebebeb;
$DVAG-color-hellgrau-95: #f5f5f5;
$DVAG-color-hellgrau-98: #979797;

// Gestaltungsfarben
$DVAG-color-dunkelblau: #004165;
$DVAG-color-dunkelblau-80: #336783;
$DVAG-color-dunkelblau-60: #668da2;
$DVAG-color-dunkelblau-40: #99b3c1;
$DVAG-color-dunkelblau-20: #ccd9e0;

$DVAG-color-rot: #be1413;
$DVAG-color-rot-80: #cb4342;
$DVAG-color-rot-60: #d87271;
$DVAG-color-rot-40: #e5a1a0;
$DVAG-color-rot-20: #f2d0cf;

$DVAG-color-petrol: #3b94a3;
$DVAG-color-petrol-80: #62a9b5;
$DVAG-color-petrol-60: #89bec7;
$DVAG-color-petrol-40: #b0d4da;
$DVAG-color-petrol-20: #d7e9ec;

$DVAG-color-gelb: #e8b50e;
$DVAG-color-gelb-80: #ecc33e;
$DVAG-color-gelb-60: #f1d26e;
$DVAG-color-gelb-40: #f5e19e;
$DVAG-color-gelb-20: #faf0ce;

$DVAG-color-gruen: #6ea318;
$DVAG-color-gruen-80: #8bb546;
$DVAG-color-gruen-60: #a8c774;
$DVAG-color-gruen-40: #c5daa2;
$DVAG-color-gruen-20: #e2ecd0;

// Transparenzen
$DVAG-boxshadow-header: 0px -4px 12px 0px rgba(0, 0, 0, 0.16);
$DVAG-boxshadow-toolbar: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
$DVAG-boxshadow-tooltip: 0px 4px 12px 2px rgba(0, 0, 0, 0.16);
$DVAG-boxshadow-fehlertooltip: 0px 4px 12px 0px rgba(0, 0, 0, 0.32);
$DVAG-boxshadow-modal-view: 0px 0px 30px 0px rgba(0, 0, 0, 0.16);
$DVAG-boxshadow-modal-view-hintergrund: rgba(102, 102, 102, 0.6);
$DVAG-boxshadow-alert-grundflaeche: rgba(255, 255, 255, 0.5) rgba(252, 252, 252, 0.8);

$spacing: (
  margin: (
    m-8: 8px,
    m-16: 16px,
    m-24: 24px,
    m-32: 32px,
    m-48: 48px,
    m-64: 64px,
  ),
  padding: (
    p-8: 8px,
    p-16: 16px,
    p-24: 24px,
    p-32: 32px,
    p-48: 48px,
    p-64: 64px,
  ),
);

$inputBorderRadius: 4px;
